import { getActionTokenless } from "./axiosConfig";

export const TEMPLATES_LIST = "TEMPLATES_LIST";
export const TEMPLATES_LIST_FAILED = "TEMPLATES_LIST_FAILED";
export const TEMPLATES_LIST_PENDING = "TEMPLATES_LIST_PENDING";

export const CATEGORIES_LIST = "CATEGORIES_LIST";
export const CATEGORIES_LIST_FAILED = "CATEGORIES_LIST_FAILED";
export const CATEGORIES_LIST_PENDING = "CATEGORIES_LIST_PENDING";

export const TEMPLATE_BY_SLUG_PENDING = "TEMPLATE_BY_SLUG_PENDING";
export const TEMPLATE_BY_SLUG = "TEMPLATE_BY_SLUG";
export const TEMPLATE_BY_SLUG_FAILED = "TEMPLATE_BY_SLUG_FAILED";

export const SIMILAR_TEMPLATE = "SIMILAR_TEMPLATE";
export const SIMILAR_TEMPLATE_FAILED = "SIMILAR_TEMPLATE_FAILED";
export const SIMILAR_TEMPLATE_PENDING = "SIMILAR_TEMPLATE_PENDING";

export function getTemplates(skip, limit, filter = "", category = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEMPLATES_LIST_PENDING,
      });
      const response = await getActionTokenless(
        `/template/?skip=${skip}&limit=${limit}&filter=${filter}&category_slug=${category}`
      );
      dispatch({
        type: TEMPLATES_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: TEMPLATES_LIST_FAILED,
        payload: error.response ? error.response.detail : "BAŞARISIZ İŞLEM",
      });
    }
  };
}

export function getTemplateCategories() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CATEGORIES_LIST_PENDING,
      });
      const response = await getActionTokenless("/template/categories/list");
      dispatch({
        type: CATEGORIES_LIST,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CATEGORIES_LIST_FAILED,
        payload: error.response ? error.response.detail : "BAŞARISIZ İŞLEM",
      });
    }
  };
}

export function getTemplateBySlug(slug) {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEMPLATE_BY_SLUG_PENDING,
      });
      const response = await getActionTokenless(`/template/${slug}`);
      dispatch({
        type: TEMPLATE_BY_SLUG,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: TEMPLATE_BY_SLUG_FAILED,
        payload: error.response ? error.response.detail : "BAŞARISIZ İŞLEM",
      });
    }
  };
}

export function getSimilarTemplates(slug) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SIMILAR_TEMPLATE_PENDING,
      });
      const response = await getActionTokenless(`/template/${slug}/similar`);
      dispatch({
        type: SIMILAR_TEMPLATE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SIMILAR_TEMPLATE_FAILED,
        payload: error.response ? error.response.detail : "BAŞARISIZ İŞLEM",
      });
    }
  };
}
