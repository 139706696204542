import { message } from 'antd'
import { getAction, postAction } from './axiosConfig'

export const ALL_RECIPIENTS_LOADING = 'ALL_RECIPIENTS_LOADING'
export const ALL_RECIPIENTS = 'ALL_RECIPIENTS'
export const ALL_RECIPIENTS_FAILED = 'ALL_RECIPIENTS_FAILED'

export const RECIPIENTS_STATISTIC_LOADING = 'RECIPIENTS_STATISTIC_LOADING'
export const RECIPIENTS_STATISTIC = 'RECIPIENTS_STATISTIC'
export const RECIPIENTS_STATISTIC_FAILED = 'RECIPIENTS_STATISTIC_FAILED'

export const JOIN_STATISTIC_LOADING = 'JOIN_STATISTIC_LOADING'
export const JOIN_STATISTIC = 'JOIN_STATISTIC'
export const JOIN_STATISTIC_FAILED = 'JOIN_STATISTIC_FAILED'

export const RECIPIENT_DETAIL_PENDING = 'RECIPIENT_DETAIL_PENDING'
export const RECIPIENT_DETAIL = 'RECIPIENT_DETAIL'
export const RECIPIENT_DETAIL_FAILED = 'RECIPIENT_DETAIL_FAILED'

export const CHECKIN = 'CHECKIN'
export const CHECKIN_PENDING = 'CHECKIN_PENDING'
export const CHECKIN_FAILED = 'CHECKIN_FAILED'

export const RESEND = 'RESEND'
export const RESEND_PENDING = 'RESEND_PENDING_PENDING'
export const RESEND_FAILED = 'RESEND_PENDING_FAILED'

export function getAllRecipients(id, skip, filter) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALL_RECIPIENTS_LOADING,
      })
      const response = await getAction(
        `/recipient/${id}/recipients?filter=${filter}&skip=${skip}&limit=10`
      )
      dispatch({
        type: ALL_RECIPIENTS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: ALL_RECIPIENTS_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getSeenStatistic(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: RECIPIENTS_STATISTIC_LOADING,
      })
      const response = await getAction(`/recipient/${id}/recipients/seen/count`)
      dispatch({
        type: RECIPIENTS_STATISTIC,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: RECIPIENTS_STATISTIC_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getJoinStatistic(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: JOIN_STATISTIC_LOADING,
      })
      const response = await getAction(`/recipient/${id}/recipients/join/count`)
      dispatch({
        type: JOIN_STATISTIC,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: JOIN_STATISTIC_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getRecipientDetail(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: RECIPIENT_DETAIL_PENDING,
      })
      const response = await getAction(`/recipient/${id}`)
      dispatch({
        type: RECIPIENT_DETAIL,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: RECIPIENT_DETAIL_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getCheckIn(id, check_in_bool) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CHECKIN_PENDING,
      })
      const response = await getAction(`/recipient/${id}/change/checkin?check_in=${check_in_bool}`)
      dispatch({
        type: CHECKIN,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: CHECKIN_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function resendInvitation(id, data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESEND_PENDING,
      })
      const response = await postAction(`/recipient/${id}/reschudele`, data)
      dispatch({
        type: RESEND,
        payload: response.data,
      })
      message.success('Mesajınız Başarı ile Gönderilmiştir.')
    } catch (error) {
      message.error('Bir Hata Meydana Geldi')
      dispatch({
        type: RESEND_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}
