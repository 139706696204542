const initialState = {
  eventInfo: {},
  recipientInfo: {},
}

export const SET_QUICK_EVENT_INFO = 'SET_QUICK_EVENT_INFO'
export const SET_QUICK_RECIPIENT_INFO = 'SET_QUICK_RECIPIENT_INFO'
const QuickInvitation = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QUICK_EVENT_INFO':
      return { ...state, eventInfo: action.payload }
    case SET_QUICK_RECIPIENT_INFO:
      return { ...state, recipientInfo: action.payload }
    default:
      return state
  }
}

export default QuickInvitation
