import { deleteAction, getAction, getActionTokenless, postAction } from '../Actions/axiosConfig'

export const SMS_PROVIDER_LIST = 'SMS_PROVIDER_LIST'
export const SMS_PROVIDER_LIST_PENDING = 'SMS_PROVIDER_LIST_PENDING'
export const SMS_PROVIDER_LIST_FAILED = 'SMS_PROVIDER_LIST_FAILED'

export const SMS_PROVIDER_PARAMETERS = 'SMS_PROVIDER_PARAMETERS'
export const SMS_PROVIDER_PARAMETERS_PENDING = 'SMS_PROVIDER_PARAMETERS_PENDING'
export const SMS_PROVIDER_PARAMETERS_FAILED = 'SMS_PROVIDER_PARAMETERS_FAILED'

export const SMS_PROVIDER_CREATE_USER = 'SMS_PROVIDER_CREATE_USER'
export const SMS_PROVIDER_CREATE_USER_FAILED = 'SMS_PROVIDER_CREATE_USER_FAILED'
export const SMS_PROVIDER_CREATE_USER_PENDING = 'SMS_PROVIDER_CREATE_USER_PENDING'

export const SMS_PROVIDERS_PENDING = 'SMS_PROVIDERS_PENDING'
export const SMS_PROVIDERS = 'SMS_PROVIDERS'
export const SMS_PROVIDERS_FAILED = 'SMS_PROVIDERS_FAILED'

export const SMS_PROVIDER_DELETE_PENDING = 'SMS_PROVIDER_DELETE_PENDING'
export const SMS_PROVIDER_DELETE = 'SMS_PROVIDER_DELETE'
export const SMS_PROVIDER_DELETE_FAILED = 'SMS_PROVIDER_DELETE_FAILED'

export const SMS_PROVIDER_UPDATE_PENDING = 'SMS_PROVIDER_UPDATE_PENDING'
export const SMS_PROVIDER_UPDATE = 'SMS_PROVIDER_UPDATE'
export const SMS_PROVIDER_UPDATE_FAILED = 'SMS_PROVIDER_UPDATE_FAILED'

export function getSMSProviders() {
  return async (dispatch) => {
    try {
      dispatch({
        type: SMS_PROVIDER_LIST_PENDING,
      })
      const response = await getActionTokenless('/smsprovider/')
      dispatch({
        type: SMS_PROVIDER_LIST,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: SMS_PROVIDER_LIST_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function SMSProviderParameters(name) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SMS_PROVIDER_PARAMETERS_PENDING,
      })
      const response = await getActionTokenless(`/smsprovider/${name}/parameters`)
      dispatch({
        type: SMS_PROVIDER_PARAMETERS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: SMS_PROVIDER_PARAMETERS_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function SMSProviderCreateUser(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SMS_PROVIDER_CREATE_USER_PENDING,
      })
      const response = await postAction(`/smsprovider/user`, data)
      dispatch({
        type: SMS_PROVIDER_CREATE_USER,
        payload: response.data,
      })
      console.log(response.data)
    } catch (error) {
      dispatch({
        type: SMS_PROVIDER_CREATE_USER_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function SMSProviderList() {
  return async (dispatch) => {
    try {
      dispatch({
        type: SMS_PROVIDERS_PENDING,
      })
      const response = await getAction(`/smsprovider/user`)
      dispatch({
        type: SMS_PROVIDERS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: SMS_PROVIDERS_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}
export function SMSUserDelete(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SMS_PROVIDER_DELETE_PENDING,
      })
      const response = await deleteAction(`/smsprovider/user/${id}`)
      dispatch({
        type: SMS_PROVIDER_DELETE,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: SMS_PROVIDER_DELETE_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}
export function SMSUserUpdate(id, data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SMS_PROVIDER_UPDATE_PENDING,
      })
      const response = await postAction(`/smsprovider/user/${id}`, data)
      dispatch({
        type: SMS_PROVIDER_UPDATE,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: SMS_PROVIDER_UPDATE_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}
