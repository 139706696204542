const initialState = {
  contactList: [],
  tags: ['event_name', 'user_firstname', 'user_lastname'],
  smsProviders: [],
  loading: true,
  notificationList: [],
}

export const CheckIn = (state = initialState, action) => {
  switch (action.type) {
    case 'CHECK_IN_SUCCESS':
      return {
        ...state,
        loading: false,
        contactList: action.payload,
        smsProviders: action.payload2,
      }
    case 'POST_CHECK_IN_SUCCESS':
      return {
        ...state,
        loading: false,
      }
    case 'CHECK_IN_PENDING':
      return {
        ...state,
        loading: false,
      }
    case 'NOTIFICATION_LIST':
      return {
        ...state,
        notificationList: action.payload,
        loading: false,
      }
    case 'NOTIFICATION_LIST_PENDING':
      return {
        ...state,
        notificationList: [],
        loading: true,
      }
    case 'NOTIFICATION_DELETE':
      return {
        ...state,
        notificationList: [
          ...state.notificationList.filter((notification) => notification._id !== action.id),
        ],
      }
    default:
      return state
  }
}

export default CheckIn
