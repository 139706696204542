import {
  CONTACT_LIST_FAILED,
  CONTACT_LIST_ADDED,
  CONTACT_LIST_PENDING,
  CONTACT_LIST_GET_FAILED,
  CONTACT_LIST_GET_SUCCESS,
  CONTACT_LIST_GET_PENDING,
  CONTACT_LIST_DELETED,
  CONTACT_LIST_DELETE_FAILED,
  CONTACT_LIST_DELETE_PENDING,
  CONTACTS_BY_LIST_ID_SUCCESS,
  CONTACTS_BY_LIST_ID_FAILED,
  CONTACTS_BY_LIST_ID_PENDING,
  SINGLE_CONTACTS_ADD_BY_ID_PENDING,
  SINGLE_CONTACTS_ADD_BY_ID_SUCCESS,
  SINGLE_CONTACTS_ADD_BY_ID_FAILED,
  ALL_MY_CONTACTS_FAILED,
  ALL_MY_CONTACTS_SUCCESS,
  ALL_MY_CONTACTS_PENDING,
  DELETE_CONTACT,
  DELETE_CONTACT_FAILED,
  DELETE_CONTACT_PENDING,
  ADD_EXCEL_CONTACT,
  ADD_EXCEL_CONTACT_FAILED,
  ADD_EXCEL_CONTACT_PENDING,
  DELETE_CONTACT_BY_LIST_PENDING,
  DELETE_CONTACT_BY_LIST,
  DELETE_CONTACT_BY_LIST_FAILED,
  GET_GUEST_DETAIL_PENDING,
  GET_GUEST_DETAIL,
  GET_GUEST_DETAIL_FAILED,
  GUEST_DETAIL_UPDATE,
  GUEST_DETAIL_UPDATE_FAILED,
  GUEST_DETAIL_UPDATE_PENDING,
} from '../Actions/ContactAction'

const initialState = {
  createContactList: {},
  deleteList: {},
  deleteLoading: {},
  deleteFailed: {},
  contactList: [],
  singleAddLoading: false,
  singleAddFailed: false,
  singleAddContact: {},
  getListLoading: false,
  contactListName: '',
  contactsByList: [],
  allMyContacts: [],
  contactDelete: {},
  deleteContactByList: {},
  deleteContactByLoading: false,
  deleteContactByFailed: false,
  addExcel: [],
  addExcelError: [],
  addExcelPending: false,
  addExcelFailed: false,
  contactDeleteLoading: false,
  contactDeleteFailed: false,
  loading: false,
  failed: false,
  guestUpdate: {},
  errMsg: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_LIST_PENDING:
      return {
        ...state,
        loading: true,
        createContactList: {},
        failed: false,
      }
    case CONTACT_LIST_ADDED:
      return {
        ...state,
        loading: false,
        createContactList: action.payload,
        contactList: [...state.contactList, action.payload],
        failed: false,
      }
    case CONTACT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        createContactList: {},
        failed: true,
        errMsg: action.payload,
      }
    case CONTACT_LIST_GET_PENDING:
      return {
        ...state,
        getListLoading: true,
        failed: false,
        contactList: [],
      }
    case CONTACT_LIST_GET_SUCCESS:
      return {
        ...state,
        getListLoading: false,
        contactList: action.payload,
        failed: false,
      }
    case CONTACT_LIST_GET_FAILED:
      return {
        ...state,
        getListLoading: false,
        contactList: [],
        failed: true,
        errMsg: action.payload,
      }
    case CONTACT_LIST_DELETE_PENDING:
      return {
        ...state,
        deleteList: {},
        deleteLoading: true,
        deleteFailed: false,
      }
    case CONTACT_LIST_DELETED:
      return {
        ...state,
        contactList: [
          ...state.contactList.filter((contactList) => contactList._id !== action.payload),
        ],
        deleteList: action.payload,
        deleteLoading: false,
        deleteFailed: false,
      }
    case CONTACT_LIST_DELETE_FAILED:
      return {
        ...state,
        deleteList: {},
        deleteLoading: false,
        deleteFailed: true,
        errMsg: action.payload,
      }
    case CONTACTS_BY_LIST_ID_PENDING:
      return {
        ...state,
        contactsByList: [],
        failed: false,
        loading: true,
      }
    case CONTACTS_BY_LIST_ID_SUCCESS:
      return {
        ...state,
        contactListName: action.payload.list_name,
        contactsByList: action.payload.contacts,
        failed: false,
        loading: false,
      }
    case CONTACTS_BY_LIST_ID_FAILED:
      return {
        ...state,
        contactsByList: [],
        failed: true,
        loading: false,
        errMsg: action.payload,
      }
    case SINGLE_CONTACTS_ADD_BY_ID_PENDING:
      return {
        ...state,
        singleAddLoading: true,
        singleAddContact: {},
        singleAddFailed: false,
      }
    case SINGLE_CONTACTS_ADD_BY_ID_SUCCESS:
      return {
        ...state,
        singleAddLoading: false,
        singleAddContact: action.payload,
        contactsByList: [...state.contactsByList, action.payload],
        singleAddFailed: false,
      }
    case SINGLE_CONTACTS_ADD_BY_ID_FAILED:
      return {
        ...state,
        singleAddLoading: false,
        singleAddContact: {},
        singleAddFailed: true,
        errMsg: action.payload,
      }
    case ALL_MY_CONTACTS_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        allMyContacts: [],
      }
    case ALL_MY_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        allMyContacts: action.payload,
      }
    case ALL_MY_CONTACTS_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
        errMsg: action.payload,
        allMyContacts: [],
      }
    case DELETE_CONTACT_PENDING:
      return {
        ...state,
        contactDeleteLoading: true,
        contactDeleteFailed: false,
        contactDelete: {},
      }
    case DELETE_CONTACT:
      return {
        ...state,
        contactDeleteLoading: false,
        contactDeleteFailed: false,
        allMyContacts: [
          ...state.allMyContacts.filter((contacts) => contacts._id !== action.payload),
        ],
        contactDelete: action.payload,
      }
    case DELETE_CONTACT_FAILED:
      return {
        ...state,
        contactDeleteLoading: false,
        contactDeleteFailed: true,
        contactDelete: {},
        errMsg: action.payload,
      }
    case ADD_EXCEL_CONTACT_PENDING:
      return {
        ...state,
        addExcel: [],
        addExcelError: [],
        addExcelFailed: false,
        addExcelPending: true,
      }
    case ADD_EXCEL_CONTACT:
      return {
        ...state,
        addExcel: action.payload.contacts,
        addExcelError: action.payload.errors,
        addExcelFailed: false,
        addExcelPending: false,
      }
    case ADD_EXCEL_CONTACT_FAILED:
      return {
        ...state,
        addExcel: {},
        addExcelFailed: true,
        addExcelError: [],
        errMsg: action.payload,
        addExcelPending: false,
      }
    case DELETE_CONTACT_BY_LIST_PENDING:
      return {
        ...state,
        deleteContactByLoading: true,
        deleteContactByFailed: false,
        deleteContactByList: {},
      }
    case DELETE_CONTACT_BY_LIST:
      return {
        ...state,
        deleteContactByLoading: false,
        deleteContactByList: action.payload,
        contactsByList: [
          ...state.contactsByList.filter((contacts) => contacts._id !== action.payload),
        ],
        deleteContactByFailed: false,
      }
    case DELETE_CONTACT_BY_LIST_FAILED:
      return {
        ...state,
        deleteContactByLoading: false,
        deleteContactByFailed: true,
        errMsg: action.payload,
        deleteContactByList: {},
      }
    case GUEST_DETAIL_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        guestUpdate: {},
      }
    case GUEST_DETAIL_UPDATE:
      return {
        ...state,
        loading: false,
        failed: false,
        guestUpdate: action.payload,
      }
    case GUEST_DETAIL_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        guestUpdate: {},
        failed: true,
        errMsg: action.payload,
      }
    default:
      return state
  }
}
