import { combineReducers, createStore, applyMiddleware } from 'redux'
import Authentication from '../Reducers/AuthenticationReducer'
import Contacts from '../Reducers/ContactReducer'
import Events from '../Reducers/EventReducer'
import Templates from '../Reducers/TemplatesReducer'
import Entegration from '../Reducers/EntegrationReducer'
import Credit from '../Reducers/CreditReducer'
import Invitation from '../Reducers/InvitationReducer'
import CreateInvitation from '../Reducers/CreateInvitationReducer'
import Report from '../Reducers/ReportReducer'
import CheckIn from '../Reducers/CheckInReducer'
import QuickInvitation from '../Reducers/QuickInvitation'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
export default createStore(
  combineReducers({
    Authentication,
    Contacts,
    Events,
    Templates,
    Entegration,
    Credit,
    CreateInvitation,
    Report,
    Invitation,
    CheckIn,
    QuickInvitation,
  }),
  composeWithDevTools(applyMiddleware(thunk))
)
