const initialState = {
  invitations: [],
  invitationListPending: false,
  invitationListFailed: false,
  invitationTypeInfo: {},
  invitationType: '',
  templateId: null,
  canvas: '',
  invitationId: null,
  rsvp: {},
  direction: {},
  qr: {},
  customButton: {},
  customButtonVisible: false,
  formFieldTypes: [],
  stepNumber: 0,
  formFields: [],
  guestList: [],
  oldContactListPending: false,
  oldContactList: [],
  invitationDate: '',
  invitationTime: '',
  selectedTime: '',
  variantIndexValue: 0,
  scheduleInfo: {},
  background: '',
  scheduleError: false,
  scheduleErrorText: '',
  smsProvider: '',
  smsText: '',
  recipientId: '',
}

export const CreateInvitation = (state = initialState, action) => {
  switch (action.type) {
    case 'INVITATION_LIST_PENDING':
      return {
        ...state,
        invitationListPending: true,
        invitations: [],
        invitationListFailed: false,
      }
    case 'INVITATION_LIST':
      return {
        ...state,
        invitationListPending: false,
        invitations: action.payload,
        invitationListFailed: false,
      }
    case 'INVITATION_LIST_FAILED':
      return {
        ...state,
        invitationListPending: false,
        invitations: [],
        invitationListFailed: true,
      }
    case 'CHANGE_TYPE':
      return {
        ...state,
        invitationType: action.payload,
      }
    case 'SELECT_TEMPLATE':
      return {
        ...state,
        templateId: action.payload,
      }
    case 'INIT_CANVAS':
      return {
        ...state,
        canvas: action.payload,
      }
    case 'TYPE_INFO':
      return {
        ...state,
        invitationTypeInfo: action.payload,
        stepNumber: action.payload.step_number,
        background: action.payload.type.template_parameters.backgroundImage.src,
        templateId: action.payload.type.template_id,
      }
    case 'INVITATION_CREATE':
      return {
        ...state,
        invitationId: action.payload._id,
      }
    case 'GET_RSVP':
      return {
        ...state,
        rsvp: action.payload.detail,
      }
    case 'SET_RSVP':
      return {
        ...state,
        rsvp: action.payload,
      }
    case 'GET_DIRECTION':
      return {
        ...state,
        direction: action.payload.detail,
      }
    case 'SET_DIRECTION':
      return {
        ...state,
        direction: action.payload,
      }
    case 'GET_QR_INFO':
      return {
        ...state,
        qr: action.payload.detail,
      }
    case 'SET_QR':
      return {
        ...state,
        qr: action.payload,
      }
    case 'GET_FORM_FIELDS_TYPES':
      return {
        ...state,
        formFieldTypes: action.payload,
      }
    case 'GET_FORM_FIELDS':
      return {
        ...state,
        formFields: action.payload,
      }
    case 'SET_FORM_FIELDS':
      return {
        ...state,
        formFields: [...state.formFields, action.payload],
      }
    case 'UPDATE_FORM_FIELDS':
      return {
        ...state,
        // TODO :  Daha iyi bir çözüm ileride bulunabilir. Maplemek iyi bir çözüm değil gibi.
        formFields: state.formFields.map((field, index) =>
          index === action.index ? { ...field, label: action.payload, key: action.key } : field
        ),
      }
    case 'UPDATE_FORM_OPTIONS':
      return {
        ...state,
        // TODO :  Daha iyi bir çözüm ileride bulunabilir. Maplemek iyi bir çözüm değil gibi.
        formFields: state.formFields.map((field, index) =>
          index === action.index
            ? {
                ...field,
                options: field.options.map((option, index) =>
                  index === action.optionIndex ? { ...option, values: action.payload } : option
                ),
              }
            : field
        ),
      }
    case 'DELETE_FORM_OPTIONS':
      let stateOptions = state.formFields[action.index].options
      stateOptions.splice(action.payload, 1)
      return {
        ...state,
        formFields: state.formFields.map((field, index) =>
          index === action.index
            ? {
                ...field,
                options: field.options.map((option, index) =>
                  index === action.optionIndex ? stateOptions : option
                ),
              }
            : field
        ),
      }
    case 'ADD_NEW_OPTION':
      return {
        ...state,
        formFields: state.formFields.map((field, index) =>
          index === action.index ? { ...field, options: [...field.options, action.payload] } : field
        ),
      }
    case 'DELETE_FORM_FIELDS':
      let stateFormField = state.formFields
      stateFormField.splice(action.payload, 1)
      return {
        ...state,
        formFields: [...stateFormField],
      }
    case 'SELECT_GUEST_LIST':
      return {
        ...state,
        guestList: action.payload,
      }
    case 'GET_CONTACT_LIST_PENDING':
      return {
        ...state,
        oldContactList: [],
        oldContactListPending: true,
      }
    case 'OLD_GUEST_LIST':
      return {
        ...state,
        oldContactList: action.payload,
        oldContactListPending: false,
      }
    case 'SET_INVITATION_DATE':
      return {
        ...state,
        invitationDate: action.payload,
      }
    case 'SET_INVITATION_TIME':
      return {
        ...state,
        invitationTime: action.payload,
      }
    case 'SELECTED_TIME':
      return {
        ...state,
        selectedTime: action.payload,
      }
    case 'SET_VARIANT_INDEX':
      return {
        ...state,
        variantIndexValue: action.payload,
      }
    case 'SET_SCHEDULE_LIST':
      return {
        ...state,
        scheduleInfo: action.payload,
        scheduleError: false,
      }
    case 'SET_SCHEDULE_ERROR':
      return {
        ...state,
        scheduleError: true,
        scheduleErrorText: action.payload,
        scheduleInfo: {},
      }
    case 'SET_BACKGROUND':
      return {
        ...state,
        background: action.payload,
      }
    case 'DISCHARGE_STATE':
      return {
        ...state,
        templateId: null,
        rsvp: {},
        direction: {},
        invitationId: null,
        qr: {},
        formFieldTypes: [],
        stepNumber: 0,
        formFields: [],
        guestList: [],
        oldContactList: [],
        invitationDate: '',
        invitationTime: '',
        variantIndexValue: 0,
        scheduleInfo: {},
        background: '',
        scheduleErrorText: '',
        customButtonVisible: false,
      }
    case 'CHANGE_CUSTOM_BUTTON':
      return {
        ...state,
        customButton: action.payload,
      }
    case 'CUSTOM_BUTTON_VISIBILITY':
      return {
        ...state,
        customButtonVisible: action.payload,
      }
    case 'CHANGE_PROVIDER':
      return {
        ...state,
        smsProvider: action.payload,
      }
    case 'SMS_TEXT':
      let filterText = action.payload.replace('{{link}}', '')
      return {
        ...state,
        smsText: filterText,
      }
    case 'UPDATE_RECIPIENT_ID':
      return {
        ...state,
        recipientId: action.payload,
      }
    default:
      return state
  }
}

export default CreateInvitation
