
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import '../styles/globals.css'
import 'antd/dist/antd.css'
import '../styles/antd.less'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import 'moment/locale/tr'

import { useQueryClient, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  EVENT_EDIT,
  GUESTS,
  GUESTS_BY_ID,
  GUEST_FIELDS,
  GUEST_LISTS,
  GUEST_LIST_BY_ID,
  EVENT_REPORT,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  INVITATION,
  EVENT_ENTEGRATION,
  CREATE_INVITATION,
  CREATE_THANKS_CARD,
  ADD_CONTACT,
  ALL_GUEST_ADD_CONTACT,
  LIST_INVITATION,
  RECIPIENT_DETAIL,
  CHECK_IN,
  CHECK_IN_MESSAGES,
  DEMO,
  DEMOQR,
  JOURNAL,
  REMINDER,
  CREATE_INVITATION_INTRODUCTION,
  PARTICIPANT_LIST,
  PARTICIPANT_LIST_DETAIL,
  ADD_PARTICIPANT,
  NOTIFICATIONS,
} from '../settings/constant'

import dynamic from 'next/dynamic'
const queryClient = new QueryClient()

const Layout = dynamic(() => import('../components/management/Layout/Layout'))
const LayoutUI = dynamic(() => import('../components/UI/Layout/Layout'))

import store from '../redux/Store/store'
import NextNProgress from '../components/NextNProgress'
import { LanguageProvider, LanguageContext } from '../contexts/LanguageContext'
import { useContext } from 'react'
import LanguageController from '../components/LanguageController'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const [headerTitle, setHeaderTitle] = useState('')
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        localStorage.getItem('authToken') !== null &&
        (router.pathname === LOGIN || router.pathname === REGISTER)
      ) {
        router.push('/')
      }
    }
  }, [])

  const setPageHeader = (header) => {
    setHeaderTitle(header)
  }

  if (
    router.pathname === LOGIN ||
    router.pathname === REGISTER ||
    router.pathname === FORGOT_PASSWORD ||
    router.pathname === RESET_PASSWORD ||
    router.pathname === INVITATION ||
    router.pathname.includes(DEMO) ||
    router.pathname === DEMOQR
  ) {
    return (
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <Provider store={store}>
            <LanguageController>
              <NextNProgress />
              <Component {...pageProps} />
            </LanguageController>
          </Provider>
        </LanguageProvider>
      </QueryClientProvider>
    )
  }
  // else if () {
  //   <Provider store={store}>
  //     <Component {...pageProps} />
  //   </Provider>;
  // }
  else
    return (
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <Provider store={store}>
            <LanguageController>
              {router.pathname === EVENT_EDIT ||
              router.pathname === GUESTS ||
              router.pathname === GUESTS_BY_ID ||
              router.pathname === GUEST_FIELDS ||
              router.pathname === GUEST_LISTS ||
              router.pathname === GUEST_LIST_BY_ID ||
              router.pathname === EVENT_REPORT ||
              router.pathname === RECIPIENT_DETAIL ||
              router.pathname === EVENT_ENTEGRATION ||
              router.pathname === ADD_CONTACT ||
              router.pathname === ALL_GUEST_ADD_CONTACT ||
              router.pathname === CHECK_IN ||
              router.pathname === LIST_INVITATION ||
              router.pathname === CHECK_IN ||
              router.pathname === JOURNAL ||
              router.pathname === REMINDER ||
              router.pathname === CHECK_IN_MESSAGES ||
              router.pathname === CREATE_INVITATION ||
              router.pathname === CREATE_INVITATION_INTRODUCTION ||
              router.pathname === PARTICIPANT_LIST ||
              router.pathname === PARTICIPANT_LIST_DETAIL ||
              router.pathname === ADD_PARTICIPANT ||
              router.pathname === NOTIFICATIONS ||
              router.pathname === CREATE_THANKS_CARD ? (
                <Layout headerTitle={headerTitle}>
                  <NextNProgress />
                  <Component headerName={setPageHeader} {...pageProps} />
                </Layout>
              ) : (
                <LayoutUI>
                  <NextNProgress />
                  <Component {...pageProps} />
                </LayoutUI>
              )}
            </LanguageController>
          </Provider>
        </LanguageProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    )
}

const __Page_Next_Translate__ = MyApp


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  