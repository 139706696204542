import React, { useContext } from 'react'
import dynamic from 'next/dynamic'
import { ConfigProvider } from 'antd'
import useTranslation from 'next-translate/useTranslation'
import { LanguageContext } from '../contexts/LanguageContext'

const LanguageController = ({ children }) => {
  const { language } = useContext(LanguageContext)

  return <ConfigProvider locale={language}>{children}</ConfigProvider>
}

export default LanguageController
