const initialState = {
  allEvents: [],
  eventTypes: [],
  eventDetail: {},
  createEvent: {},
  updateEvent: {},
  eventParameters: {},
  form_parameters: {},
  updateFormParameters: {},
  deleteEvent: '',
  uploadImage: '',
  listFormParameters: [],
  eventDetailLoading: true,
  eventCreateLoading: false,
  parametersLoading: false,
  loading: true,
  formParameterLoading: true,
  failed: false,
  errMsg: '',
}

const Events = (state = initialState, action) => {
  if (action.type === 'ALL_EVENTS_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'EVENT_DETAIL_PENDING') {
    return {
      ...state,
      eventDetailLoading: true,
    }
  } else if (action.type === 'CREATE_EVENT_PENDING') {
    return {
      ...state,
      eventCreateLoading: true,
    }
  } else if (action.type === 'UPDATE_EVENT_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'GET_EVENT_TYPES_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'GET_EVENT_TYPES_PARAMATERS_PENDING') {
    return {
      ...state,
      parametersLoading: true,
    }
  } else if (action.type === 'GET_FORM_PARAMETERS_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'GET_LIST_FORM_PARAMETERS_PENDING') {
    return {
      ...state,
      formParameterLoading: true,
    }
  } else if (action.type === 'UPDATE_FORM_PARAMETERS_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'DELETE_EVENT_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'UPLOAD_EVENT_IMAGE_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'EVENT_DETAIL_SUCCESS') {
    return {
      ...state,
      eventDetailLoading: false,
      eventDetail: action.payload,
    }
  } else if (action.type === 'ALL_EVENTS_SUCCESS') {
    return {
      ...state,
      loading: false,
      allEvents: action.payload,
    }
  } else if (action.type === 'CREATE_EVENT_SUCCESS') {
    return {
      ...state,
      createEvent: action.payload,
      eventCreateLoading: false,
      errMsg: '',
    }
  } else if (action.type === 'UPDATE_EVENT_SUCCESS') {
    return {
      ...state,
      loading: false,
      updateEvent: action.payload,
    }
  } else if (action.type === 'GET_EVENT_TYPES_SUCCESS') {
    return {
      ...state,
      loading: false,
      eventTypes: action.payload,
    }
  } else if (action.type === 'GET_EVENT_TYPES_PARAMATERS_SUCCESS') {
    return {
      ...state,
      parametersLoading: false,
      eventParameters: action.payload?.parameters,
    }
  } else if (action.type === 'GET_FORM_PARAMETERS_SUCCESS') {
    return {
      ...state,
      form_parameters: action.payload,
      loading: false,
    }
  } else if (action.type === 'GET_LIST_FORM_PARAMETERS_SUCCESS') {
    return {
      ...state,
      list_form_parameters: action.payload,
      formParameterLoading: false,
    }
  } else if (action.type === 'UPDATE_FORM_PARAMETERS_SUCCESS') {
    return {
      ...state,
      updateFormParameters: action.payload,
      loading: false,
    }
  } else if (action.type === 'DELETE_EVENT_SUCCESS') {
    return {
      ...state,
      deleteEvent: action.payload,
      loading: false,
    }
  } else if (action.type === 'UPLOAD_EVENT_IMAGE_SUCCESS') {
    return {
      ...state,
      loading: false,
      uploadImage: action.payload,
    }
  } else if (action.type === 'ALL_EVENTS_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'EVENT_DETAIL_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'CREATE_EVENT_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
      eventCreateLoading: false,
    }
  } else if (action.type === 'UPDATE_EVENT_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
      loading: false,
    }
  } else if (action.type === 'GET_EVENT_TYPES_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'GET_EVENT_TYPES_PARAMATERS_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'GET_FORM_PARAMETERS_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'GET_LIST_FORM_PARAMETERS_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'UPDATE_FORM_PARAMETERS_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'DELETE_EVENT_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else if (action.type === 'UPLOAD_EVENT_IMAGE_FAILED') {
    return {
      ...state,
      failed: true,
      errMsg: action.payload,
    }
  } else {
    return state
  }
}

export default Events
