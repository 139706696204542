// EVENT MANAGMENT ROUTES
export const EVENT_EDIT = '/m/event/[event_id]/edit'
export const GUEST_LISTS = '/m/event/[event_id]/guest-list'
export const GUEST_LIST_BY_ID = '/m/event/[event_id]/guest-list/[id]'
export const ADD_CONTACT = '/m/event/[event_id]/guest-list/[id]/add-contact'
export const GUEST_FIELDS = '/m/event/[event_id]/guest-list/guest-fields'
export const GUESTS = '/m/event/[event_id]/guests'
export const GUESTS_BY_ID = '/m/event/[event_id]/guests/[id]'
export const EVENT_REPORT = '/m/event/[event_id]/reports'
export const RECIPIENT_DETAIL = '/m/event/[event_id]/reports/[recipient_id]'
export const EVENT_CREATE = '/m/event/create'
export const EVENT_ENTEGRATION = '/m/event/[event_id]/integration'
export const CREATE_INVITATION = '/m/event/[event_id]/create-invitation'
export const CREATE_INVITATION_INTRODUCTION = '/m/event/[event_id]/create-invitation/introduction'
export const CREATE_THANKS_CARD = '/m/event/[event_id]/create-thanks-card'
export const LIST_INVITATION = '/m/event/[event_id]/list-invitation'
export const ALL_GUEST_ADD_CONTACT = '/m/event/[event_id]/guests/add-contact'
export const CHECK_IN_MESSAGES = '/m/event/[event_id]/check-in-messages'
export const CHECK_IN = '/m/event/[event_id]/check-in'
export const JOURNAL = '/m/event/[event_id]/journal'
export const REMINDER = '/m/event/[event_id]/reminder/[invitation_id]'
export const PARTICIPANT_LIST = '/m/event/[event_id]/participant-list'
export const PARTICIPANT_LIST_DETAIL = '/m/event/[event_id]/participant-list/[id]'
export const ADD_PARTICIPANT = '/m/event/[event_id]/participant-list/[id]/add-participant'
export const NOTIFICATIONS = '/m/event/[event_id]/notifications'

//EVENT LIST
export const EVENT_LIST = '/m/events'

//AUTHENTICATON
export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'

// INVITATION

export const INVITATION = '/invitations'
export const DEMO = '/demo'
export const DEMOQR = '/demo/qr'
