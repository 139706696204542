import {
  PENDING,
  FAILED,
  SUCCESS,
  FORGOT_FAILED,
  FORGOT_SUCCESS,
  ME_INFO,
  LOGOUT,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_PENDING,
  GET_COUNTRIES,
  GET_COUNTRIES_FAILED,
} from '../Actions/AuthenticationAction'

const initialState = {
  token: {},
  userInfo: {},
  loading: false,
  failed: false,
  errMsg: '',
  forgotFailed: false,
  forgotTrue: false,
  forgotTrueMsg: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PENDING:
      return {
        ...state,
        loading: true,
        token: {},
        failed: false,
      }
    case SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
        failed: false,
      }

    case FAILED:
      return {
        ...state,
        loading: false,
        token: {},
        failed: true,
        errMsg: action.payload,
      }
    case FORGOT_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotTrue: true,
        forgotTrueMsg: action.payload,
        forgotFailed: false,
      }
    case FORGOT_FAILED:
      return {
        ...state,
        loading: false,
        forgotTrue: false,
        forgotFailed: true,
        errMsg: action.payload,
      }
    case ME_INFO:
      return {
        ...state,
        loading: false,
        failed: false,
        userInfo: action.payload,
      }
    case LOGOUT:
      return {
        ...state,
        loading: false,
        userInfo: {},
        failed: false,
      }
    case UPDATE_USER_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.payload,
        failed: true,
      }
    default:
      return state
  }
}
