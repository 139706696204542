import {
  TEMPLATES_LIST,
  TEMPLATES_LIST_FAILED,
  TEMPLATES_LIST_PENDING,
  CATEGORIES_LIST,
  CATEGORIES_LIST_FAILED,
  CATEGORIES_LIST_PENDING,
  TEMPLATE_BY_SLUG,
  TEMPLATE_BY_SLUG_PENDING,
  TEMPLATE_BY_SLUG_FAILED,
  SIMILAR_TEMPLATE_FAILED,
  SIMILAR_TEMPLATE,
  SIMILAR_TEMPLATE_PENDING,
} from "../Actions/TemplateAction";

const initialState = {
  templates: [],
  templateCount: 0,
  templatesLoading: false,
  templatesFailed: false,
  errMsg: "",
  categories: [],
  categoriesLoading: false,
  categoriesFailed: false,
  categoriesErrMsg: "",
  templateInfo: {},
  templateInfoFailed: false,
  templateInfoLoading: false,
  templateInfoErrMsg: "",
  similarTemplates: [],
  similarTemplatesFailed: false,
  similarTemplatesLoading: false,
  similarTemplatesErrMsg: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATES_LIST_PENDING:
      return {
        ...state,
        templates: [],
        templateCount: 0,
        templatesLoading: true,
        templatesFailed: false,
      };
    case TEMPLATES_LIST:
      return {
        ...state,
        templates: action.payload.templates,
        templateCount: action.payload.templates_count,
        templatesLoading: false,
        templatesFailed: false,
      };
    case TEMPLATES_LIST_FAILED:
      return {
        ...state,
        templates: [],
        templateCount: 0,
        templatesFailed: true,
        templatesLoading: false,
        errMsg: action.payload,
      };
    case CATEGORIES_LIST_PENDING:
      return {
        ...state,
        categories: [],
        categoriesLoading: true,
        categoriesFailed: false,
      };
    case CATEGORIES_LIST:
      return {
        ...state,
        categories: action.payload,
        categoriesLoading: false,
        categoriesFailed: false,
      };
    case CATEGORIES_LIST_FAILED:
      return {
        ...state,
        categories: [],
        categoriesLoading: false,
        categoriesFailed: true,
        categoriesErrMsg: action.payload,
      };
    case TEMPLATE_BY_SLUG_PENDING:
      return {
        ...state,
        templateInfo: {},
        templateInfoLoading: true,
        templateInfoFailed: false,
      };
    case TEMPLATE_BY_SLUG:
      return {
        ...state,
        templateInfo: action.payload,
        templateInfoLoading: false,
        templateInfoFailed: false,
      };
    case TEMPLATE_BY_SLUG_FAILED:
      return {
        ...state,
        templateInfo: {},
        templateInfoLoading: false,
        templateInfoFailed: true,
        templateInfoErrMsg: action.payload,
      };
    case SIMILAR_TEMPLATE_PENDING:
      return {
        ...state,
        similarTemplates: [],
        similarTemplatesFailed: false,
        similarTemplatesLoading: true,
      };
    case SIMILAR_TEMPLATE:
      return {
        ...state,
        similarTemplates: action.payload,
        similarTemplatesLoading: false,
        similarTemplatesFailed: false,
      };
    case SIMILAR_TEMPLATE_FAILED:
      return {
        ...state,
        similarTemplates: [],
        similarTemplatesLoading: false,
        similarTemplatesFailed: true,
        similarTemplatesErrMsg: action.payload,
      };
    default:
      return state;
  }
};
