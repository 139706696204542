import TR from 'antd/lib/locale/tr_TR'
import EN from 'antd/lib/locale/en_US'
import { createContext, useState } from 'react'
import { useRouter } from 'next/router'

export const LanguageContext = createContext({
  language: TR,
  changeLanguage: () => {},
})

export const LanguageProvider = ({ children }) => {
  const router = useRouter()
  const [language, setLanguage] = useState(router.locale === 'tr' ? TR : EN)

  /**
   * It sets a cookie called NEXT_LOCALE with the value of the lang parameter, and sets the expiration
   * date to 100 days from now
   * @param {'en' | 'tr'} lang - The language code to set the locale to.
   */
  function persistLocaleCookie(lang) {
    const date = new Date()
    const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
    date.setTime(date.getTime() + expireMs)
    document.cookie = `NEXT_LOCALE=${lang};expires=${date.toUTCString()};path=/`
  }

  /**
   * It checks if the language is already selected, if it is, it returns. If it isn't, it sets the
   * language to the selected language
   * @param {'en' | 'tr'} lang - The language you want to change to.
   */
  const changeLanguage = (lang) => {
    if (lang === language) return
    if (lang === 'tr') {
      setLanguage(TR)
      persistLocaleCookie('tr')
    } else {
      setLanguage(EN)
      persistLocaleCookie('en')
    }
  }

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}
