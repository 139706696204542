import {
  ALL_RECIPIENTS_FAILED,
  ALL_RECIPIENTS_LOADING,
  ALL_RECIPIENTS,
  RECIPIENTS_STATISTIC,
  RECIPIENTS_STATISTIC_FAILED,
  RECIPIENTS_STATISTIC_LOADING,
  JOIN_STATISTIC,
  JOIN_STATISTIC_FAILED,
  JOIN_STATISTIC_LOADING,
  RECIPIENT_DETAIL,
  RECIPIENT_DETAIL_FAILED,
  RECIPIENT_DETAIL_PENDING,
  RESEND,
  RESEND_FAILED,
  RESEND_PENDING,
  CHECKIN,
  CHECKIN_FAILED,
  CHECKIN_PENDING,
} from '../Actions/ReportsAction'
const initialState = {
  allRecipients: [],
  allRecipientsLoading: false,
  allRecipientsFailed: false,
  errMsg: '',
  seenInfo: [],
  seenInfoLoading: false,
  seenInfoFailed: false,
  recipientsCount: 0,
  joinInfo: [],
  joinInfoLoading: false,
  joinInfoFailed: false,
  recipientInfo: {},
  recipientInfoPending: false,
  recipientInfoFailed: false,
  resendLoading: false,
}

export const Report = (state = initialState, action) => {
  switch (action.type) {
    case ALL_RECIPIENTS_LOADING:
      return {
        ...state,
        allRecipients: [],
        allRecipientsLoading: true,
        allRecipientsFailed: false,
      }
    case ALL_RECIPIENTS:
      return {
        ...state,
        allRecipients: action.payload.recipients,
        recipientsCount: action.payload.recipients_count,
        allRecipientsLoading: false,
        allRecipientsFailed: false,
      }
    case ALL_RECIPIENTS_FAILED:
      return {
        ...state,
        allRecipients: [],
        allRecipientsLoading: false,
        allRecipientsFailed: true,
        errMsg: action.payload,
      }
    case RECIPIENTS_STATISTIC_LOADING:
      return {
        ...state,
        seenInfo: [],
        seenInfoLoading: true,
        seenInfoFailed: false,
      }
    case RECIPIENTS_STATISTIC:
      return {
        ...state,
        seenInfo: action.payload,
        seenInfoLoading: false,
        seenInfoFailed: false,
      }
    case RECIPIENTS_STATISTIC_FAILED:
      return {
        ...state,
        seenInfo: [],
        seenInfoLoading: false,
        seenInfoFailed: true,
      }
    case JOIN_STATISTIC_LOADING:
      return {
        ...state,
        joinInfo: [],
        joinInfoLoading: true,
        joinInfoFailed: false,
      }
    case JOIN_STATISTIC:
      return {
        ...state,
        joinInfo: action.payload,
        joinInfoLoading: false,
        joinInfoFailed: false,
      }
    case RESEND_PENDING:
      return {
        ...state,
        resendLoading: true,
      }
    case RESEND:
      return {
        ...state,
        resendLoading: false,
      }
    case RESEND_FAILED:
      return {
        ...state,
        resendLoading: false,
      }
    case JOIN_STATISTIC_FAILED:
      return {
        ...state,
        joinInfo: [],
        joinInfoLoading: false,
        joinInfoFailed: true,
      }
    case RECIPIENT_DETAIL_PENDING:
      return {
        ...state,
        recipientInfo: {},
        recipientInfoPending: true,
        recipientInfoFailed: false,
      }
    case RECIPIENT_DETAIL:
      return {
        ...state,
        recipientInfo: action.payload,
        recipientInfoPending: false,
        recipientInfoFailed: false,
      }
    case RECIPIENT_DETAIL_FAILED:
      return {
        ...state,
        recipientInfo: {},
        recipientInfoPending: false,
        recipientInfoFailed: true,
      }
    default:
      return state
  }
}

export default Report
