const initialState = {
  credit: {},
  spendings: [],
  purchasings: [],
  loading: true,
  failed: false,
};

export const Credit = (state = initialState, action) => {
  if (action.type === "GET_CREDIT_SUCCESS") {
    return {
      ...state,
      credit: action.payload,
      loading: false,
    };
  } else if (action.type === "GET_SPENDINGS_SUCCESS") {
    return {
      ...state,
      spendings: action.payload,
      loading: false,
    };
  } else if (action.type === "GET_PURCHASING_SUCCESS") {
    return {
      ...state,
      purchasings: action.payload,
      loading: false,
    };
  } else if (
    action.type === "GET_CREDIT_FAILED" ||
    action.type === "GET_PURCHASING_FAILED" ||
    action.type === "GET_SPENDINGS_FAILED"
  ) {
    return {
      ...state,
      failed: true,
      loading: false,
    };
  } else if (
    action.type === "GET_CREDIT_PENDING" ||
    action.type === "GET_PURCHASING_PENDING" ||
    action.type === "GET_SPENDINGS_PENDING"
  ) {
    return {
      ...state,
      loading: true,
    };
  } else {
    return state;
  }
};

export default Credit;
