import { postAction, getAction, deleteAction } from './axiosConfig'

export const CONTACT_LIST_ADDED = 'CONTACT_LIST_ADDED'
export const CONTACT_LIST_FAILED = 'CONTACT_LIST_FAILED'
export const CONTACT_LIST_PENDING = 'CONTACT_LIST_PENDING'

export const CONTACT_LIST_GET_SUCCESS = 'CONTACT_LIST_GET_SUCCESS'
export const CONTACT_LIST_GET_FAILED = 'CONTACT_LIST_GET_FAILED'
export const CONTACT_LIST_GET_PENDING = 'CONTACT_LIST_GET_PENDING'

export const CONTACT_LIST_DELETED = 'CONTACT_LIST_DELETED'
export const CONTACT_LIST_DELETE_FAILED = 'CONTACT_LIST_DELETE_FAILED'
export const CONTACT_LIST_DELETE_PENDING = 'CONTACT_LIST_DELETE_PENDING'

export const CONTACTS_BY_LIST_ID_PENDING = 'CONTACTS_BY_LIST_ID_PENDING'
export const CONTACTS_BY_LIST_ID_SUCCESS = 'CONTACTS_BY_LIST_ID_SUCCESS'
export const CONTACTS_BY_LIST_ID_FAILED = 'CONTACTS_BY_LIST_ID_FAILED'

export const DELETE_CONTACT_BY_LIST = 'DELETE_CONTACT_BY_LIST'
export const DELETE_CONTACT_BY_LIST_PENDING = 'DELETE_CONTACT_BY_LIST_PENDING'
export const DELETE_CONTACT_BY_LIST_FAILED = 'DELETE_CONTACT_BY_LIST_FAILED'

export const SINGLE_CONTACTS_ADD_BY_ID_PENDING = 'SINGLE_CONTACTS_BY_LIST_ID_PENDING'
export const SINGLE_CONTACTS_ADD_BY_ID_SUCCESS = 'SINGLE_CONTACTS_BY_LIST_ID_SUCCESS'
export const SINGLE_CONTACTS_ADD_BY_ID_FAILED = 'SINGLE_CONTACTS_BY_LIST_ID_FAILED'

export const ALL_MY_CONTACTS_SUCCESS = 'ALL_MY_CONTACTS_SUCCESS'
export const ALL_MY_CONTACTS_FAILED = 'ALL_MY_CONTACTS_FAILED'
export const ALL_MY_CONTACTS_PENDING = 'ALL_MY_CONTACTS_PENDING'

export const DELETE_CONTACT = 'DELETE_CONTACT'
export const DELETE_CONTACT_PENDING = 'DELETE_CONTACT_PENDING'
export const DELETE_CONTACT_FAILED = 'DELETE_CONTACT_FAILED'

export const ADD_EXCEL_CONTACT = 'ADD_EXCEL_CONTACT'
export const ADD_EXCEL_CONTACT_FAILED = 'ADD_EXCEL_CONTACT_FAILED'
export const ADD_EXCEL_CONTACT_PENDING = 'ADD_EXCEL_CONTACT_PENDING'

export const GET_GUEST_DETAIL = 'GET_GUEST_DETAIL'
export const GET_GUEST_DETAIL_PENDING = 'GET_GUEST_DETAIL_PENDING'
export const GET_GUEST_DETAIL_FAILED = 'GET_GUEST_DETAIL_FAILED'

export const GUEST_DETAIL_UPDATE = 'GUEST_DETAIL_UPDATE'
export const GUEST_DETAIL_UPDATE_PENDING = 'GUEST_DETAIL_UPDATE_PENDING'
export const GUEST_DETAIL_UPDATE_FAILED = 'GUEST_DETAIL_UPDATE_FAILED'

export function createContactList(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONTACT_LIST_PENDING,
      })
      const response = await postAction('/contactlist', data)
      dispatch({
        type: CONTACT_LIST_ADDED,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: CONTACT_LIST_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getContactList() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONTACT_LIST_GET_PENDING,
      })
      const response = await getAction('/contactlist/')
      dispatch({
        type: CONTACT_LIST_GET_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: CONTACT_LIST_GET_PENDING,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}
export function deleteContactList(contactListId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONTACT_LIST_DELETE_PENDING,
      })
      const response = await deleteAction('/contactlist/' + contactListId)
      dispatch({
        type: CONTACT_LIST_DELETED,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: CONTACT_LIST_DELETE_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getContactsByContactList(contactListId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONTACTS_BY_LIST_ID_PENDING,
      })
      const response = await getAction('/contactlist/' + contactListId + '/list')
      dispatch({
        type: CONTACTS_BY_LIST_ID_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: CONTACTS_BY_LIST_ID_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function createContactByListId(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SINGLE_CONTACTS_ADD_BY_ID_PENDING,
      })
      const response = await postAction('/contact', data)
      dispatch({
        type: SINGLE_CONTACTS_ADD_BY_ID_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: SINGLE_CONTACTS_ADD_BY_ID_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getAllMyContacts() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALL_MY_CONTACTS_PENDING,
      })
      const response = await getAction(`/contact`)
      dispatch({
        type: ALL_MY_CONTACTS_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: ALL_MY_CONTACTS_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function deleteMyContact(contactId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_CONTACT_PENDING,
      })
      const response = await deleteAction(`/contact/` + contactId)
      dispatch({
        type: DELETE_CONTACT,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: DELETE_CONTACT_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function addExcelContact(contactListId, file) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_EXCEL_CONTACT_PENDING,
      })
      const response = await postAction(`/contactlist/${contactListId}/upload`, file)
      dispatch({
        type: ADD_EXCEL_CONTACT,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: ADD_EXCEL_CONTACT_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function getDetailGuest(contactID) {
  return getAction(`/contact/${contactID}`)
}

export function updateDetailGuest(contactID, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: GUEST_DETAIL_UPDATE_PENDING })
      const response = await postAction(`/contact/${contactID}`, data)
      dispatch({
        type: GUEST_DETAIL_UPDATE,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: GUEST_DETAIL_UPDATE_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}

export function deleteMyContactByList(contactId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_CONTACT_BY_LIST_PENDING,
      })
      const response = await deleteAction(`/contact/` + contactId)
      dispatch({
        type: DELETE_CONTACT_BY_LIST,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: DELETE_CONTACT_BY_LIST_FAILED,
        payload: error.response ? error.response.detail : 'BAŞARISIZ İŞLEM',
      })
    }
  }
}
