const initialState = {
  loading: false,
  failed: false,
  currentIndex: 0,
  willJoin: true,
  formValues: [],
  height: '0%',
  bottom: '-100%',
  adults: 1,
  children: 0,
  chooseJoinStatus: false,
  guestInfoEdited: false,
  invitation: {},
  refreshing: false,
}

export const Invitation = (state = initialState, action) => {
  if (action.type === 'SET_INDEX') {
    return {
      ...state,
      currentIndex: action.payload,
    }
  } else if (action.type === 'GET_INVITATION_PENDING') {
    return {
      ...state,
      loading: true,
    }
  } else if (action.type === 'REFRESH_INVITATION') {
    return {
      ...state,
      refreshing: true,
    }
  } else if (action.type === 'GET_INVITATION_SUCCESS') {
    return {
      ...state,
      loading: false,
      refreshing: false,
      invitation: action.payload,
    }
  } else if (action.type === 'GET_INVITATION_FAILED') {
    return {
      ...state,
      loading: false,
      invitation: {},
      failed: true,
    }
  } else if (action.type === 'SET_WILL_JOIN') {
    return {
      ...state,
      willJoin: action.payload,
    }
  } else if (action.type === 'SET_ADULTS') {
    return {
      ...state,
      adults: action.payload,
    }
  } else if (action.type === 'SET_CHILDREN') {
    return {
      ...state,
      children: action.payload,
    }
  } else if (action.type === 'SET_FORM_VALUES') {
    return {
      ...state,
      formValues: [...state.formValues, action.payload],
    }
  } else if (action.type === 'RESET_FORM_VALUES') {
    return {
      ...state,
      formValues: [],
    }
  } else if (action.type === 'SET_HEIGHT') {
    return {
      ...state,
      height: action.payload,
    }
  } else if (action.type === 'SET_BOTTOM') {
    return {
      ...state,
      bottom: action.payload,
    }
  } else if (action.type === 'SET_JOIN_STATUS') {
    return {
      ...state,
      chooseJoinStatus: action.payload,
    }
  } else if (action.type === 'SET_GUEST_INFO_EDITED') {
    return {
      ...state,
      guestInfoEdited: action.payload,
    }
  } else {
    return state
  }
}

export default Invitation
