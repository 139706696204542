import {
  SMS_PROVIDER_LIST,
  SMS_PROVIDER_LIST_FAILED,
  SMS_PROVIDER_LIST_PENDING,
  SMS_PROVIDER_PARAMETERS,
  SMS_PROVIDER_PARAMETERS_PENDING,
  SMS_PROVIDER_PARAMETERS_FAILED,
  SMS_PROVIDER_CREATE_USER,
  SMS_PROVIDER_CREATE_USER_FAILED,
  SMS_PROVIDER_CREATE_USER_PENDING,
  SMS_PROVIDERS,
  SMS_PROVIDERS_FAILED,
  SMS_PROVIDERS_PENDING,
  SMS_PROVIDER_DELETE,
  SMS_PROVIDER_UPDATE_PENDING,
  SMS_PROVIDER_UPDATE,
  SMS_PROVIDER_UPDATE_FAILED,
} from '../Actions/EntegrationAction'

const initialState = {
  smsProviders: [],
  userSMSProviders: [],
  loading: false,
  failed: false,
  errMsg: '',
  userSMSProviderPending: false,
  userSMSProviderFailed: false,
  providerParameters: [],
  providerParametersLoading: false,
  providerParametersFailed: false,
  providerParametersErrMsg: '',
  smsProviderUserCreate: {},
  smsProviderUserCreateFailed: false,
  smsProviderUserCreateLoading: false,
  smsProviderUserCreateErrMsg: '',
  updateSMSProvider: {},
}

const Entegration = (state = initialState, action) => {
  switch (action.type) {
    case SMS_PROVIDER_LIST_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        smsProviders: [],
      }
    case SMS_PROVIDER_LIST:
      return {
        ...state,
        loading: false,
        failed: false,
        smsProviders: action.payload,
      }
    case SMS_PROVIDER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
        errMsg: action.payload,
        smsProviders: [],
      }
    case SMS_PROVIDER_PARAMETERS_PENDING:
      return {
        ...state,
        providerParametersLoading: true,
        providerParametersFailed: false,
        providerParameters: [],
      }
    case SMS_PROVIDER_PARAMETERS:
      return {
        ...state,
        providerParametersLoading: false,
        providerParametersFailed: false,
        providerParameters: action.payload,
      }
    case SMS_PROVIDER_PARAMETERS_FAILED:
      return {
        ...state,
        providerParametersLoading: false,
        providerParametersFailed: true,
        providerParameters: [],
        providerParametersErrMsg: action.payload,
      }
    case SMS_PROVIDER_CREATE_USER_PENDING:
      return {
        ...state,
        smsProviderUserCreate: {},
        smsProviderUserCreateLoading: true,
        smsProviderUserCreateFailed: false,
      }
    case SMS_PROVIDER_CREATE_USER:
      return {
        ...state,
        userSMSProviders: [...state.userSMSProviders, action.payload],
        smsProviderUserCreate: action.payload,
        smsProviderUserCreateLoading: false,
        smsProviderUserCreateFailed: false,
      }
    case SMS_PROVIDER_CREATE_USER_FAILED:
      return {
        ...state,
        smsProviderUserCreate: {},
        smsProviderUserCreateLoading: false,
        smsProviderUserCreateFailed: true,
        smsProviderUserCreateErrMsg: action.payload,
      }
    case SMS_PROVIDERS_PENDING:
      return {
        ...state,
        userSMSProviders: [],
        userSMSProviderPending: true,
        userSMSProviderFailed: false,
      }
    case SMS_PROVIDERS:
      return {
        ...state,
        userSMSProviderPending: false,
        userSMSProviderFailed: false,
        userSMSProviders: action.payload,
      }
    case SMS_PROVIDERS_FAILED:
      return {
        ...state,
        userSMSProviderPending: false,
        userSMSProviders: [],
        userSMSProviderFailed: action.payload,
      }
    case SMS_PROVIDER_DELETE:
      return {
        ...state,
        userSMSProviders: [
          ...state.userSMSProviders.filter((smsProvider) => smsProvider._id != action.payload),
        ],
      }
    case SMS_PROVIDER_UPDATE:
      return {
        ...state,
        updateSMSProvider: action.payload,
      }
    default:
      return state
  }
}
export default Entegration
