import {
  getAction,
  postActionTokenless,
  postAction,
  getActionTokenless,
  getActionTokenParameters,
} from './axiosConfig'
import router from 'next/router'

export const PENDING = 'PENDING'
export const SUCCESS = 'SUCCESS'
export const FAILED = 'FAILED'

export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_FAILED = 'FORGOT_FAILED'

export const ME_INFO = 'ME_INFO'
export const LOGOUT = 'LOGOUT'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED'

export function login(data, returnUrl = undefined) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PENDING,
      })
      const response = await postActionTokenless('/login/access', data)
      dispatch({
        type: SUCCESS,
        payload: response.data.access_token,
      })
      localStorage.setItem('authToken', response.data.access_token)
      if (window.posthog) {
        window.posthog.identify(`${data.username}`)
      }
      if (returnUrl) router.push(`${returnUrl}`)
      else {
        let eventResponse = await getActionTokenParameters(
          '/event/list',
          response.data.access_token
        )
        router.push('/m/events')
      }
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error.response ? error.response.data.detail : 'İŞLEM BAŞARISIZ!',
      })
    }
  }
}

export function register(data, returnUrl = undefined, agent_code) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PENDING,
      })
      let response
      if (agent_code) {
        response = await postActionTokenless(`/register?agent_code=${agent_code}`, data)
        localStorage.setItem('authToken', response.data.access_token)
        router.push('/paketler/')
        return null
      } else response = await postActionTokenless('/register', data)
      dispatch({
        type: SUCCESS,
        payload: response.data.access_token,
      })
      localStorage.setItem('authToken', response.data.access_token)
      if (window.posthog) {
        window.posthog.identify(`${data.username}`)
      }
      if (returnUrl) router.push(`${returnUrl}`)
      else router.push('/m/event/create?newUser')
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error.response ? error.response.data.detail : 'İŞLEM BAŞARISIZ!',
      })
    }
  }
}

export function googleLogin(token = data, returnUrl = undefined) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PENDING,
      })
      const response = await postActionTokenless('/login/google-login', {
        token,
      })
      dispatch({
        type: SUCCESS,
        payload: response.data.access_token,
      })
      localStorage.setItem('authToken', response.data.access_token)
      let meInfo = await getAction('/me')
      if (window.posthog) {
        try {
          window.posthog.identify(`${meInfo.data.username}`)
        } catch (e) {
          throw e
        }
      }

      if (returnUrl) router.push(`${returnUrl}`)
      else router.push('/m/events')
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error.response ? error.response.data.detail : 'İŞLEM BAŞARISIZ!',
      })
    }
  }
}
export function resetPassword(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PENDING,
      })
      const response = await postActionTokenless('/reset-password', data)
      dispatch({
        type: SUCCESS,
        payload: response.data.access_token,
      })
      localStorage.setItem('authToken', response.data.access_token)
      router.push('/m/events')
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error.response ? error.response.data.detail : 'İŞLEM BAŞARISIZ!',
      })
    }
  }
}
export function forgotPassword(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PENDING,
      })
      const response = await postActionTokenless('/forgot-password', data)
      dispatch({
        type: FORGOT_SUCCESS,
        payload: 'E-posta adresinize şifre yenileme bağlantısı gönderildi.',
      })
    } catch (error) {
      dispatch({
        type: FORGOT_FAILED,
        payload: error.response ? error.response.data.detail : 'İŞLEM BAŞARISIZ!',
      })
    }
  }
}

export function getMeInfo() {
  /* 
  "/me" request tum layoutlarda gonderiliyor
  User objesi kredi bilgisinide bulunduruyor.
  */
  return async (dispatch) => {
    try {
      dispatch({
        type: PENDING,
      })
      const response = await getAction('/me')
      dispatch({
        type: ME_INFO,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error.response ? error.response.data.detail : 'İŞLEM BAŞARISIZ!',
      })
    }
  }
}
